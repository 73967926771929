
const videoSrc = "https://firebasestorage.googleapis.com/v0/b/chicpic-15f68.appspot.com/o/Full%20Screen%20Landing%20Video%20720p%20(2).mp4?alt=media&token=6f3dc75d-b32b-42c7-bfd7-e1fdf7c80dd6";

const VideoPlayer = () => {
    return (
        <div className='video'>
            <video loop autoPlay muted width="100%">
                <source src={videoSrc} type="video/mp4"/>
            </video>
        </div>
    )
};

export default VideoPlayer;
