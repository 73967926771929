import ContentSection from "../ContentSection";
import VideoPlayer from "./VideoPlayer";

const Intro = () => {
        return (
        <div className="intro-video">
            <div className="intro-content">
                <h3 className="title">Find, buy and share your clothes in ChicPic</h3>
                <p className="body">Share your fashion styles with people who have the same body style as yours. Get offers on fashion e-commerces that have your size.</p>
            </div>
            <div className="roadmap"></div>
            <VideoPlayer />
        </div>
    );
}

export default Intro;