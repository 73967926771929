import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1 style={{paddingTop: "1em"}}>Privacy Policy</h1>
            <p style={{textAlign: "start", padding: "1em 3em", paddingBottom: "3em"}}>
                <br/>
                <h4>Introduction:</h4>
                <br/>
                Chicpic ("we," "us," or "our") is committed to safeguarding your privacy and ensuring the security of
                your personal information. This Privacy Policy outlines how we collect, use, and protect your data
                when you use our mobile application ("Chicpic" or "the Application"). By accessing or using Chicpic,
                you consent to the practices described in this Privacy Policy.'
                <br/><br/>
                <h4>Information We Collect:</h4>
                <br/>
                User-Provided Information: When you register an account with Chicpic we may collect personal
                information such as your name, email address, and body size information, including but not limited to
                height, weight, bust size, hips size, waist size, and shoe size. Alternatively, you can login to Chicpic
                by
                third party authentications such as Google and Facebook.
                Automatically Collected Information: Chicpic may collect certain information automatically, including
                your device's unique identifier, IP address, operating system, and usage data related to the
                Application. This data may also send to Sentry for issue tracking and analysis purposes.
                <br/><br/>
                <h4>Use of Information:</h4>
                <br/>
                We use the information we collect for the following purposes:
                <br/>
                ● To provide you with personalized clothing recommendations based on your body size and
                preferences.
                <br/>
                ● To improve and optimize Chicpic's services and user experience.
                <br/>
                ● To communicate with you regarding updates, offers, and promotions.
                <br/><br/>
                <h4>Data Security:</h4>
                <br/>
                We take the security of your data seriously. We employ reasonable measures to protect your personal
                information from unauthorized access, disclosure, alteration, and destruction. However, no method of
                transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the
                absolute security of your data.
                <br/><br/>
                <h4>Disclosure of Information:</h4>
                <br/>
                We do not sell or rent your personal information to third parties. We may share your information with
                our trusted partners, service providers, and business affiliates for the sole purpose of providing you
                with our services. We may also disclose your information if required by law or to protect our rights,
                privacy, safety, or property.
                <br/><br/>
                <h4>Your Choices:</h4>
                <br/>
                You have the right to access, update, or delete your personal information stored with Chicpic. You can
                make these changes through your account settings or by contacting us at founder@chicpic.app. You
                can also opt-out of receiving promotional emails from us by following the instructions included in each
                email.
                <br/><br/>
                <h4>Changes to this Privacy Policy:</h4>
                Chicpic reserves the right to modify or revise this Privacy Policy at any time. Users will be notified
                of any changes, and it is their responsibility to review the updated Privacy Policy. Your continued use
                of
                Chicpic after any modifications indicates your acceptance of the new policy.
                <br/><br/>
                <h4>Contact Information:</h4>
                <br/>
                If you have any questions or concerns about this Privacy Policy or your data privacy, please contact
                us at founder@chicpic.app
            </p>
        </div>
    );
}

export default PrivacyPolicy;
