import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Intro from './components/Intro';
import Profile from './components/Profile';
import Search from './components/Search';
import ECommerce from './components/Ecommerce';
import Share from './components/Share';
import Email from './components/Email';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import Footer from "./components/Footer";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Header />
                                <Intro />
                                <Profile />
                                <Search />
                                <ECommerce />
                                <Share />
                                <Email />
                                <Footer/>
                            </>
                        }
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
