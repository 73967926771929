import ImageSection from "../ImageSection";
import ContentSection from "../ContentSection";
import Roadmap from "../Roadmap";

const Ecommerce = () => {
	const header = 'Exclusive fashion items in e-commerce for you:';
	const body = 'You will see many fashion items on e-commerce from local to international fashion brands that relatively close to your body type and body size. You can definitely search among final sales and end-of-season sales but the results are only clothes for your size.';

	const source1 = require("../Assets/Images/ecommerce-1.png");
	const source2 = require("../Assets/Images/ecommerce-2.png");

	return (
		<div className="e-commerce">
			<ImageSection source1={source1} source2={source2} />
			<Roadmap number={2} />
			<ContentSection header={header} body={body} />
		</div>
	);
}

export default Ecommerce;