import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <ul className="footer-buttons">
                <li><a href='mailto:info@chicpic.app' className="footer-link">Contact Us</a></li>
                <li><Link to="/privacy-policy" className="footer-link">Privacy Policy</Link></li>
                <li><Link to="/terms-conditions" className="footer-link">Terms & Conditions</Link></li>
            </ul>
        </footer>
    );
}

export default Footer;
