import ContentSection from "../ContentSection";
import ImageSection from "../ImageSection";
import Roadmap from "../Roadmap";
const Search = () => {
  const header = 'Search with photos:';
  const body = 'fashion items in photos are searchable among items in e-commerce for similar products. You just upload a photo or from shared photos by users on Chicpic, we identify fashion items in the photos and search the similar products in the e-commerce section of Chicpic for your size.';

  const source1 = require("../Assets/Images/search-1.png");
  const source2 = require("../Assets/Images/search-2.png");
  return (
    <div className="search">
      <ContentSection header={header} body={body} />
      <Roadmap number={1} />
      <ImageSection source1={source1} source2={source2} />
    </div>
  );
}

export default Search;