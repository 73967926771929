import { useState } from "react";
import axios from "axios";

const Email = () => {
	function isValidEmail(email) {
		if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
		  return true
		}
		return false
	}

	async function sendEmail(emailAddress) {

		// check email address validation
		if (!isValidEmail(emailAddress)) {
			setMessage('Your email is not valid.')
			setIsShowMessage(true)
			return
		}

		// call api
		const url = "https://chicpic-15f68-default-rtdb.firebaseio.com/wait-list.json"

		const data = {
			email: emailAddress,
			submitted_at: Intl.DateTimeFormat('en-us', {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				timeZone: 'UTC'
			}).format(Date.now())
		}

		
		try {
			setIsSubmitting(true);
			await axios.post(url, data);
			setMessage('✅ Thanks. We will be in touch with you later.')
			setEmailAddress('')
		} catch (e) {
			setMessage('Error caused in submitting email. Try again later.')
		}
		setIsSubmitting(false);
		setIsShowMessage(true)
		
	}
	
	const [emailAddress, setEmailAddress] = useState('');
	const [message, setMessage] = useState('');
	const [isShowMessage, setIsShowMessage] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	return (
		<div className="email">
			<h1>Our new app is on the way!</h1>
			<p>Sign up to be the first to know when it launches 💖</p>
			<div className="email-input">
				<input
					type="email"
					placeholder="Your email address"
					value={emailAddress}
					onChange={
						function (e) {
							setEmailAddress(e.target.value)
							setIsShowMessage(false)
						}
					}
				/>
				<button disabled={isSubmitting} type="submit" onClick={() => sendEmail(emailAddress)}>Notify Me</button>
			</div>
			<div> {isShowMessage ? <div className='email-input-message'>{message}</div> : ''} </div>
		</div>
	);
}

export default Email;