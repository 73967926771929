import ImageSection from "../ImageSection";
import ContentSection from "../ContentSection";
import Roadmap from "../Roadmap";

const Profile = () => {
    const header = 'Make a profile:'
    const body = 'Make your general dress profile based on body shape and size, age, and gender on Chicpic.'

    const source1 = require("../Assets/Images/profile-1.png");
    const source2 = require("../Assets/Images/profile-2.png");
    
    return (
        <div className="profile">
            <ImageSection source1 = {source1} source2={source2} />
            <Roadmap number={0} />
            <ContentSection header={header} body={body}/>
        </div>
    );
}

export default Profile;