const roadmap = ({ number }) => {
    return (
        <div className="roadmap">
            {number > 0 ? (<div className="roadmap-line"></div>): (<div className="empty"></div>)}
            <div className="roadmap-circle">
                <p>{number + 1}</p>
            </div>
            {number < 3 ? (<div className="roadmap-line"></div>): (<div className="empty"></div>)}
        </div>
    );
}

export default roadmap;