import ContentSection from "../ContentSection";
import Roadmap from "../Roadmap";

const Share = () => {
	const header = 'Share your photos:';
	const body = 'Post pictures of your fashion style on the app to get ideas from others with similar body types on how to dress, match clothes, and coordinate colors.';
	const source = require("../Assets/Images/share-1.png");
	return (
		<div className="share">
			<ContentSection header={header} body={body} />
			<Roadmap number={3} />
			<div className="share-image">
				<img src={source} className="share-image1" />
			</div>
		</div>
	);
}

export default Share;