import React from 'react';

const TermsConditions = () => {
    return (
        <div>
            <h1 style={{paddingTop: "1em"}}>Terms & Conditions</h1>
            <p style={{textAlign: "start", padding: "1em 3em", paddingBottom: "3em"}}>
                <br/>
                <h4>Acceptance of Terms:</h4>
                <br/>
                By accessing or using Chicpic mobile application, you agree to comply with and be bound by the
                following terms and conditions ("Terms"). If you do not agree to these Terms, please do not use
                Chicpic.
                <br/><br/>
                <h4> Description of Service: </h4>
                <br/>
                Chicpic is a mobile application that allows users to find the best-fitting clothing items from various
                Canadian online shops in Canada based on their provided body size information, including but not
                limited to height, weight, bust size, hips size, waist size, and shoe size. Chicpic only provides
                clothing
                recommendations and links to external online shops where users can make purchases on the main
                website of the fashion brands.
                <br/><br/>
                <h4>User Registration:</h4>
                <br/>
                To use the full range of services provided by Chicpic, users must register an account. When
                registering, you are required to provide accurate and complete information about your body size. You
                are solely responsible for maintaining the confidentiality of your account information.
                <br/><br/>
                <h4>Privacy:</h4>
                <br/>
                Your use of Chicpic is subject to our Privacy Policy. By using Chicpic, you consent to the collection
                and use of your personal information as described in the Privacy Policy.
                <br/><br/>
                <h4>User Responsibilities:</h4>
                <br/>
                You agree to use Chicpic in compliance with all applicable laws and regulations in Canada. You are
                solely responsible for the accuracy and completeness of the body size information you provide.
                Chicpic does not guarantee the availability or quality of products from third-party online shops.
                <br/><br/>
                <h4>Intellectual Property:</h4>
                <br/>
                All content, including but not limited to text, images, logos, and graphics on Chicpic, is protected by
                copyright and other intellectual property rights. However, many of the fashion items on Chicpic are
                data from third party websites, which are open to the public. Users may not reproduce, distribute, or
                create derivative works based on the content without prior written consent from Chicpic.
                <br/><br/>
                <h4>Limitation of Liability:</h4>
                <br/>
                Chicpic is provided on an "as is" and "as available" basis. We make no warranties or representations
                about the accuracy or completeness of the information provided through Chicpic. In no event shall
                Chicpic be liable for any direct, indirect, incidental, special, or consequential damages.
                <br/><br/>
                <h4>Termination:</h4>
                <br/>
                Chicpic may terminate your account or access to the Application at its discretion, with or without
                notice, if you violate these Terms or engage in any conduct that Chicpic deems to be in violation of its
                policies.
                <br/><br/>
                <h4>Changes to Terms:</h4>
                <br/>
                Chicpic reserves the right to modify or revise these Terms at any time. Users will be notified of any
                changes, and it is their responsibility to review the updated Terms. Your continued use of Chicpic after
                any modifications indicates your acceptance of the new Terms.
                <br/><br/>
                <h4>Contact Information:</h4>
                <br/>
                If you have any questions or concerns about these Terms, please contact us at founder@chicpic.app

            </p>
        </div>
    );
}

export default TermsConditions;
